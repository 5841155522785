<template>
  <div class="p-1">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>

    <b-row v-if="schLoading" align-h="center" class="mb-1">
      <b-col class="text-center" lg="7" md="9">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-col>
    </b-row>
    <template v-else>
      <b-row align-h="center" class="mb-1">
        <b-col class="text-center" lg="7" md="9">
          <b-img
            v-if="school.logo == null || school.logo == ''"
            src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
            style="object-fit: contain"
            fluid
            height="100px"
            width="100px"
          />
          <b-img
            v-else
            :src="school.logo"
            style="object-fit: contain"
            fluid
            height="100px"
            width="100px"
          />
          <div class="mt-1">
            <h3>{{ school.name }}</h3>
            <h5>
              <feather-icon icon="MapPinIcon" class="mr-50" />
              {{ school.address }}
            </h5>
            <h5>
              <feather-icon icon="PhoneIcon" class="mr-50" />
              {{ school.phone || school.mobile }}
            </h5>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center" v-if="!loggedIn">
        <b-col md="6">
          <b-card>
            <div v-if="otpLoading" class="text-center">
              <b-spinner
                class="m-1"
                style="width: 2.5rem; height: 2.5rem"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
            <b-row v-else-if="showOtp">
              <b-col md="12">
                <h5 class="mb-1">
                  We sent a verification code to your contact ******{{
                    otpContact.slice(-3)
                  }}. Enter the code in the field below.
                </h5>
              </b-col>
              <b-col md="12" class="d-flex">
                <b-form-input
                  v-for="(item, ind) in enteredCode"
                  :key="ind"
                  type="number"
                  max="9"
                  min="0"
                  :no-wheel="true"
                  :ref="'code' + ind"
                  v-model="enteredCode[ind]"
                  class="text-center mx-50"
                  @input="moveNext(ind)"
                />
              </b-col>
              <b-col md="12" class="mt-1 text-center">
                <b-button
                  variant="primary"
                  :disabled="verifyToggle"
                  @click="verifyOtp()"
                >
                  Verify
                </b-button>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="12">
                <h5 class="mb-1">
                  Please enter your contact number to proceed.
                </h5>
              </b-col>
              <b-col xl="9" sm="8">
                <b-form-group
                  label="Contact No."
                  invalid-feedback="Contact No. is required."
                  class="mb-0"
                >
                  <vue-tel-input
                    v-model="otpContact"
                    :inputOptions="vueTelOptions"
                    :dropdownOptions="dropOptions"
                    @input="CheckOTPContact"
                  ></vue-tel-input>
                  <small class="mr-1 text-danger" v-if="otpError"
                    >Contact number is required.</small
                  >
                </b-form-group>
              </b-col>
              <b-col xl="3" sm="4">
                <b-button
                  variant="primary"
                  block
                  class="btn-icon mt-2"
                  @click="sendOtp()"
                >
                  Send
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <div v-else>
        <b-modal
          id="modal-meeting"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          title="Live Class"
          size="lg"
        >
          <b-row class="">
            <b-col lg="3" md="6">
              <b-form-group
                label="Class"
                invalid-feedback="Class is required."
                ref="class"
              >
                <v-select
                  v-model="myObj.cID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  :disabled="dataLoading"
                  placeholder="Select class."
                  @input="FillSections()"
                />
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6">
              <b-form-group
                label="Section"
                invalid-feedback="section is required."
                ref="sec"
              >
                <v-select
                  v-model="myObj.secID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currentSections"
                  :reduce="(val) => val.id"
                  label="section"
                  :clearable="false"
                  :disabled="dataLoading"
                  placeholder="Select section."
                />
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6">
              <b-form-group
                label="Subject"
                invalid-feedback="Subject is required."
                ref="sub"
              >
                <v-select
                  v-model="myObj.subjectID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="allSubjects"
                  :reduce="(val) => val.id"
                  label="subject"
                  :clearable="false"
                  :disabled="dataLoading"
                  placeholder="Select subject."
                />
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6">
              <b-form-group
                label="Teacher"
                invalid-feedback="Teacher is required."
                ref="teacher"
              >
                <v-select
                  v-model="myObj.tID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="staffList"
                  :reduce="(opt) => opt.id"
                  label="name"
                  :clearable="false"
                  :disabled="dataLoading"
                  placeholder="Select teacher"
                />
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6">
              <b-form-group
                label="Date"
                invalid-feedback="Date is required."
                ref="date"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.dateTimeOfClass"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6">
              <b-form-group
                label="Link"
                invalid-feedback="Link is required."
                ref="link"
              >
                <b-form-input
                  v-model.trim="myObj.link"
                  placeholder="Enter link"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="center" class="mt-1">
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="success"
                :disabled="request"
                @click="save()"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else class="align-middle">Save</span>
              </b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-row align-h="center" v-if="dataLoading || itemLoading">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            type="grow"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-row>
        <b-row
          align-h="center"
          v-else-if="items.length == 0"
          style="height: 20vh"
        >
          <h1 class="mt-1 mb-1" style="align-self: center">No records found</h1>
        </b-row>
        <b-row class="mt-2" align-h="start" v-else>
          <b-col
            v-for="(c, index) in items"
            :key="index"
            class=""
            xl="3"
            lg="4"
            md="4"
            sm="6"
            cols="12"
          >
            <b-card no-body>
              <b-card
                :bg-variant="color[index % color.length]"
                text-variant="white"
                class="mb-1"
                style="
                  min-height: 150px;
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
                "
                body-class="p-1"
              >
                <b-card-title
                  ><h1 class="mt-1 text-white">
                    {{ getSubject(c.subjectID) }}
                  </h1>
                  <h5 class="text-white mb-1">
                    {{ getClass(c.cID, c.secID) }}
                  </h5>
                  <h4 class="text-white">{{ getTeacher(c.tID) }}</h4>
                </b-card-title>
              </b-card>

              <b-card-title class="px-1 mb-0">
                <b
                  >{{
                    new Date(c.dateTimeOfClass).toLocaleDateString("en-UK", {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    })
                  }}
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(c.dateTimeOfClass))
                  }}</b
                >
              </b-card-title>

              <b-card-text class="p-1">
                <!-- <b-card-text>
                  {{ c.description }}
                </b-card-text> -->

                <div class="d-flex justify-content-between">
                  <b-button
                    @click="openWebinar(c)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="color[index % color.length]"
                    class="btn-icon"
                  >
                    Join Now
                  </b-button>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    if (!this.$route.query.db || !this.$route.query.cID) {
      this.showError = true;
    } else {
      this.db = this.$route.query.db;
      this.cID = this.$route.query.cID;
      this.LoadSchool();
    }
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.teacher.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.subject.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  data() {
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      rights: {},
      config: {
        enableTime: true,
        altFormat: "j M, Y h:i K",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
      },
      searchQuery: "",
      dataLoading: false,
      itemLoading: false,
      items: [
        // {
        //   subject: "English",
        //   date: "2024-04-06T10:00:00",
        //   description: "Group project: Social life in the modern age",
        //   teacher: "Muhammad Ali",
        //   pic: "https://cdn.cloudious.net/file-1678094351350-637549624.png",
        // },
        // {
        //   subject: "Physics",
        //   date: "2024-04-07T11:30:00",
        //   description: "Chp#1 - Introduction to Physics",
        //   teacher: "Uzair Ahmed",
        //   pic: "https://cdn.cloudious.net/file-1678518247915-575510498.png",
        // },
        // {
        //   subject: "Mathematics",
        //   date: "2024-04-07T12:30:00",
        //   description: "Calculus",
        //   teacher: "Muhammad Athar",
        //   pic: "https://cdn.cloudious.net/file-1678464900590-606838574.jpg",
        // },
      ],
      myObj: {
        id: 0,
        cID: 0,
        secID: 0,
        subjectID: 0,
        tID: 0,
        dateTimeOfClass: null,
        link: "",
        campusID: this.$store.state.userData.cId,
      },
      currentItem: {},
      showLink: false,
      linkLoading: false,
      request: false,
      classes: [],
      classesOptions: [],
      currentSections: [],
      allSubjects: [],
      staffList: [],
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      showError: false,
      db: "",
      cID: "",
      school: {},
      schLoading: false,
      loggedIn: this.$store.state.stdLoggedIn,
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      otpLoading: false,
      otpContact: "",
      otpError: false,
      showOtp: false,
      code: "",
      enteredCode: [],
      verifyToggle: false,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
    }),

    openWebinar(item) {
      window.open(item.link, "_blank");
    },

    CheckOTPContact(s, e) {
      if (e.valid) {
        this.otpError = false;
        this.otpContact = e.number;
      } else {
        this.otpError = true;
      }
    },
    async sendOtp() {
      if (this.otpContact == "" || this.otpError) {
        this.$bvToast.toast("Please enter the contact number", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        this.otpLoading = true;
        this.code = `${Math.floor(1000 + Math.random() * 9000)}`;
        var resp = await this.postObj({
          url:
            this.$store.state.domain +
            "Chat/SendWhatsApp?db=/r6Q0RYpjkdemxZWlFH9Ig==&cID=2",
          body: {
            message: this.code + " is your verification code.",
            media: "",
            contacts: this.otpContact,
            whatsapp: true,
            userID: 1,
          },
          message: "OTP sent to your WhatsApp.",
          context: this,
          token: this.token,
        });
        this.otpLoading = false;
        if (resp) {
          this.enteredCode = new Array(4).fill(null);
          // console.log(this.code);
          this.verifyToggle = true;
          this.showOtp = true;
          setTimeout(() => {
            this.$refs["code0"][0].focus();
          }, 300);
        }
      }
    },
    moveNext(ind) {
      // console.log(this.enteredCode);
      if (ind != this.enteredCode.length - 1 && this.enteredCode[ind]) {
        let elem = this.$refs[`code${ind + 1}`][0];
        elem.focus();
      }
      if (this.enteredCode.includes(null) || this.enteredCode.includes("")) {
        this.verifyToggle = true;
      } else this.verifyOtp();
      // else this.verifyToggle = false;
    },
    async verifyOtp() {
      this.verifyToggle = true;
      let check = this.enteredCode.join("");
      if (check == this.code) {
        this.LoadItems();
        this.loggedIn = true;
        this.$store.commit("setStdLoggedIn", true);
        this.showOtp = false;
        this.code = "";
        this.enteredCode = [];
        this.verifyToggle = false;
      } else {
        this.$bvToast.toast("OTP entered is incorrect.", {
          title: "Login Falied!",
          variant: "danger",
          solid: true,
        });
        this.verifyToggle = false;
      }
    },

    getClass(cID, id) {
      let obj = this.classes.find((el) => el.cls.id == cID);
      if (obj) {
        let sec = obj.sections.find((el) => el.id == id);
        return sec ? `${obj.cls.name} - ${sec.section}` : `${obj.cls.name}`;
      } else return "";
    },
    getSubject(id) {
      let obj = this.allSubjects.find((el) => el.id == id);
      return obj ? obj.subject : "";
    },
    getTeacher(id) {
      let obj = this.staffList.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    FillSections(text) {
      let obj = this.classes.find((el) => el.cls.id == this.myObj.cID);
      if (obj) {
        this.currentSections = obj.sections;
      } else this.currentSections = [];

      if (text != "filled") this.myObj.secID = 0;
    },

    CheckClass() {
      var elem = this.$refs["class"];
      if (this.myObj.cID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSec() {
      var elem = this.$refs["sec"];
      if (this.myObj.secID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSub() {
      var elem = this.$refs["sub"];
      if (this.myObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTeacher() {
      var elem = this.$refs["teacher"];
      if (this.myObj.tID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.myObj.dateTimeOfClass) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckLink() {
      var elem = this.$refs["link"];
      if (this.myObj.link == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      this.CheckClass();
      this.CheckSec();
      this.CheckSub();
      this.CheckTeacher();
      this.CheckDate();
      this.CheckLink();
      if (
        this.CheckClass() == false ||
        this.CheckSec() == false ||
        this.CheckSub() == false ||
        this.CheckTeacher() == false ||
        this.CheckDate() == false ||
        this.CheckLink() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        console.log(this.myObj);
        if (this.myObj.id == 0) {
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "liveClasses?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Live Class added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;

          if (status) {
            this.LoadItems();
            this.$bvModal.hide("modal-meeting");
          }
        } else {
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "liveClasses/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Live Class updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadItems();
            this.$bvModal.hide("modal-meeting");
          }
        }
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "liveClasses/" +
            id +
            "?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId,
          message: "Live Class deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadItems();
      }
    },

    async LoadSchool() {
      this.schLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetSelected?id=" +
          this.cID +
          "&db=" +
          this.db,
        token: this.token,
      };
      let res = await this.get(obj);
      this.schLoading = false;
      if (res == "NotFound" || Array.isArray(res)) this.showError = true;
      else {
        setTimeout(() => {
          this.otpError = false;
        }, 300);
        this.school = res;
        this.LoadData();
        if (this.loggedIn) this.LoadItems();
      }
      // console.log("sch:", this.school);
    },
    async LoadItems() {
      this.itemLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "liveClasses?db=" +
          this.db +
          "&cId=" +
          this.cID,
        token: this.token,
      };
      this.items = await this.get(obj);
      this.itemLoading = false;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.db +
          "&cId=" +
          this.cID +
          "&dID=0",
        token: this.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      var obj2 = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.db +
          "&cId=" +
          this.cID,
        token: this.token,
      };
      this.allSubjects = await this.get(obj2);

      var obj3 = {
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.db +
          "&cId=" +
          this.cID +
          "&dptID=0&status=present",
        token: this.token,
      };
      this.staffList = await this.get(obj3);

      this.dataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.set-aspect {
  /* aspect-ratio: 16/9; */
  height: 85vh;
}
</style>
